import Vue from "vue";
import Vuex from "vuex";
import { setToken, removeToken } from '@/api/auth.js'
import createVuexAlong from 'vuex-along'

Vue.use(Vuex);

export default new Vuex.Store({
    // 这里的 都是 vuex a状态
    state: {
        userInfo: {},
        isLogin: false,
        token: '',  // 👈他就是 veux里的 token
        active: 0,
    },
    // 操作状态（数据）   
    mutations: {
        // 保存用户信息
        SET_USER_INFO: (state, userInfo) => {
            state.userInfo = userInfo
        },
        // 保存sn
        // SET_TH: (state, info) => {
        //     state.sn = info.sn
        //     state.thname = info.thname
        // },
        // // 保存sn
        // SET_LIST: (state, i) => {
        //     state.wid = i.wid
        //     state.wsname = i.wsname
        // },
        // 保存token
        SET_TOKEN: (state, token) => {
            // 这个就是
            state.token = state.userInfo.uid + "," + token
            // 👆这个
            state.isLogin = true
            // 👇这个 set 存了
            setToken(state.token)
        },
        // 退出登录 清空数据
        DEL_TOKEN: (state) => {
            state.token = ''
            state.isLogin = false
            state.userInfo = {}
            removeToken()
        },
    },
    // 处理异步请求   
    actions: {
        // 登录
        Login({ commit }, info) {
            //  登录传 info token
            commit('SET_USER_INFO', info)
            commit('SET_TOKEN', info.token)
        },
        // // 列表
        // THList({ commit }, i) {
        //     commit('SET_LIST', i)
        // },
        // // 详情
        // THDetail({ commit }, i) {
        //     commit('SET_TH', i)
        // },
        // // 登出
        Logout({ commit }) {
            commit('DEL_TOKEN')
        },
        // 获取用户信息
        // GET_USER_INFO: ({ commit }, userInfo) => {
        //     commit('SET_USER_INFO', userInfo)
        // }
    },
    modules: {

    },
    plugins: [createVuexAlong(
        {
            name: 'datav-vuex-along',
            justSession: true,
            local: {},
            session: {},
        }
    )]
})

